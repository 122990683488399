import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../shared/services';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {
  ColumnType, FilterField,
  ResourceTableComponent,
  TableAction,
  TableButton,
  TableColumn,
  TableEvent
} from '../../shared/modules/resource/resource-table/resource-table.component';
import {ConfirmDialogComponent} from '../../components';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  @ViewChild(ResourceTableComponent) tableComponent: ResourceTableComponent;

  columns: Array<TableColumn> = [
    {name: 'username', title: 'users.fields.username', type: ColumnType.STRING},
    {
      name: 'email', title: 'users.fields.email', type: ColumnType.STRING,
      options: {flex: 2}
    },
    {
      name: 'firstName', title: 'users.fields.firstName', type: ColumnType.STRING,
      options: {flex: 2}
    },
    {
      name: 'lastName', title: 'users.fields.lastName', type: ColumnType.STRING,
      options: {flex: 2}
    },
    {
      name: 'role', title: 'users.fields.role', type: ColumnType.STRING, transform: (elem) => elem.name
    },
    {
      name: 'createdAt', title: 'common.created', type: ColumnType.DATETIME,
      options: {flex: 2}
    },
    {
      name: 'updatedAt', title: 'common.updated', type: ColumnType.DATETIME,
      options: {flex: 2}
    },
    {name: 'actions', title: 'common.actions', type: ColumnType.ACTION}
  ];

  actions: Array<TableAction> = [
    {
      name: 'edit', icon: 'edit', tooltip: 'common.edit', classes: 'gray',
      routerLinkFn: (row) => ['/users/edit/', row.id]
    },
    {name: 'delete', icon: 'clear', tooltip: 'common.delete', classes: 'red bold'}
  ];

  buttons: Array<TableButton> = [
    {name: 'add', text: 'users.table.new', icon: 'add', color: 'primary', routerLinkFn: () => ['/users/create']}
  ];

  filters: Array<FilterField> = [
    {name: 'q', placeholder: 'common.filter', icon: 'search'}
  ];

  constructor(public userService: UserService,
              private dialog: MatDialog,
              private router: Router,
              private translateService: TranslateService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
  }

  onAction(event: TableEvent) {
    switch (event.name) {
      case 'delete':
        this.dialog.open(ConfirmDialogComponent, {
          disableClose: true, autoFocus: false, width: '400px',
          data: {
            title: 'users.dialog.deleteTitle',
            text: 'users.dialog.deleteQuestion',
            textArgs: {name: event.data.username},
            buttons: [{name: 'delete', text: 'common.delete', color: 'warn'}]
          }
        }).afterClosed().subscribe(name => {
          if (name === 'delete') {
            this.userService.deleteOne(event.data.id).subscribe(resp => {
              this.tableComponent.applyFilter({});
              this.translateService.get('users.dialog.deletedText', {name: event.data.username})
                .subscribe(text => {
                  this.snackBar.open(text);
                });
            });
          }
        });
        break;
    }
  }
}
