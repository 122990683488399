import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../shared/services';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../shared/models';
import {MatSnackBar} from '@angular/material';
import {Validators} from '@angular/forms';
import {AuthService} from '../../../shared/modules/auth/auth.service';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {
  FieldType,
  FormButton,
  FormEvent,
  FormField
} from '../../../shared/modules/resource/resource-form/resource-form.component';
import {MustMatch, ValidateEmailNotTaken, ValidateUsernameNotTaken} from '../../../shared/util/validateEmailNotTaken';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  user: User;

  fields: Array<FormField> = [
    {name: 'firstName', label: 'users.fields.firstName', type: FieldType.INPUT},
    {name: 'lastName', label: 'users.fields.lastName', type: FieldType.INPUT},
    {
      name: 'email', label: 'users.fields.email', type: FieldType.INPUT, hint: 'users.form.emailHint',
      options: {type: 'email'}, validators: [Validators.email, Validators.required]
    },
    {
      name: 'username', label: 'users.fields.username', type: FieldType.INPUT, validators: [Validators.required]
    },
    {
      name: 'password', label: 'users.fields.password', type: FieldType.INPUT, validators: [Validators.required],
      options: {type: 'password'}
    },
    {
      name: 'passwordRepeat',
      label: 'users.fields.passwordRepeat',
      type: FieldType.INPUT,
      validators: [Validators.required],
      options: {type: 'password'}
    },
    {name: 'enabled', label: 'common.enabled', type: FieldType.TOGGLE},
    {
      name: 'role', label: 'users.fields.role', type: FieldType.RADIOBUTTON, validators: [Validators.required],
      options: {
        orientation: 'vertical',
        valueKey: 'id',
        values: []
      }
    }
  ];

  options: any = {
    validators: MustMatch('password', 'passwordRepeat')
  };

  buttons: Array<FormButton> = [
    {name: 'save', text: 'common.save', color: 'primary'},
    {name: 'cancel', text: 'common.cancel'}
  ];

  constructor(public userService: UserService,
              private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private translateService: TranslateService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let getCall = Observable.create(o => {
        let user = new User();
        user.enabled = true;
        o.next(user);
        o.complete();
      });
      if (params['id']) {
        getCall = this.userService.getOne(+params['id']);
        _.find(this.fields, ['name', 'password']).validators = [];
        _.find(this.fields, ['name', 'passwordRepeat']).validators = [];
      } else if (this.router.url.endsWith('/me')) {
        getCall = this.authService.getAuthUser();
        _.find(this.fields, ['name', 'password']).validators = [];
        _.find(this.fields, ['name', 'passwordRepeat']).validators = [];
        _.remove(this.fields, (item) => {
          return item.name === 'role' || item.name === 'enabled';
        });
      }
      getCall.subscribe(user => {
        user.password = '';
        this.user = user;
        _.find(this.fields, ['name', 'email']).asyncValidator =
          ValidateEmailNotTaken.createValidator(this.userService, user.email);
        _.find(this.fields, ['name', 'username']).asyncValidator =
          ValidateUsernameNotTaken.createValidator(this.userService, user.username);
      });
    });
    this.userService.getRoles().subscribe(roles => {
      _.find(this.fields, ['name', 'role'])['options']['values'] = roles.map(role => {
        return {value: role, name: role.name};
      });
    });
  }

  onButton(event: FormEvent) {
    if (event.name === 'cancel') {
      this.goBack();
    } else if (event.valid && event.name === 'save' && this.user.id) {
      _.defaults(event.data, this.user);
      this.userService.updateOne(event.data).subscribe(resp => {
        this.translateService.get('users.form.updatedText', {name: this.user.username}).subscribe(text => {
          this.snackBar.open(text);
          this.goBack();
        });
      });
    } else if (event.valid && event.name === 'save' && !this.user.id) {
      this.userService.createOne(event.data).subscribe(resp => {
        this.translateService.get('users.form.createdText', {name: event.data.username}).subscribe(text => {
          this.snackBar.open(text);
          this.goBack();
        });
      });
    }
  }

  private goBack() {
    this.router.navigate(['/users']);
  }
}
