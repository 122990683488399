import {ModuleWithProviders, NgModule} from '@angular/core';
import {EventBroker} from './event-broker';

@NgModule({
  declarations: [],
  exports: []
})
export class EventModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: EventModule,
      providers: [
        EventBroker
      ]
    };
  }
}
