import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {GenericResponse, RestService} from "ngx-restful";
import {Site} from "../models";
import {environment} from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class EmailService extends RestService<Site, GenericResponse> {


  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public upload(formData) {
    return this.httpClient.post(this.getBaseUrlPath() + "/v1/templates/template", formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  getBaseUrlPath(): string {
    return environment.baseUrl + "email";
  }

}
