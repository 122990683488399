import {Injectable} from '@angular/core';
import {TranslateLoader} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import * as en from '../../../assets/i18n/en.json';
import * as hr from '../../../assets/i18n/hr.json';

@Injectable()
export class TranslateFileLoader implements TranslateLoader {

  constructor() {
  }

  getTranslation(lang: string): Observable<any> {
    let json = en;
    switch (lang.toLowerCase()) {
      case 'en':
        json = en;
        break;
      case 'hr':
        json = hr;
        break;
      default:
        json = en;
    }
    return Observable.create((obs) => obs.next(json['default']));
  }
}
