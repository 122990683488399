import {MatPaginator, MatSort} from '@angular/material';
import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, Observable} from 'rxjs';
import {RestService} from 'ngx-restful';

export class ResourceDataSource<T> extends DataSource<T> {

  data = new BehaviorSubject<T[]>([]);
  _filterChange = new BehaviorSubject({});

  constructor(private service: RestService<T, any>,
              private _sort: MatSort,
              private _paginator: MatPaginator,
              private extraPath: string = null,
              private extraParams: any = {}) {
    super();

    this.fetchData();

    this._sort.sortChange.subscribe(() => {
      this.fetchData();
    });

    this._paginator.page.subscribe(() => {
      this.fetchData();
    });

    this._filterChange.subscribe(() => {
      this.fetchData();
    });
  }

  get filter(): any {
    return this._filterChange.value;
  }

  set filter(filter: any) {
    this._filterChange.next(filter);
  }

  public connect(): Observable<T[]> {
    return this.data.asObservable();
  }

  public disconnect() {
  }

  public fetchData(extraParams: any = {}) {
    if (this.extraPath !== null) {
      this.service.query({params: this.createParams(extraParams)}, this.extraPath).subscribe(data => {
        this.data.next(data);
      });
    } else {
      this.service.query({params: this.createParams(extraParams)}).subscribe(data => {
        this.data.next(data);
      });
    }
  }

  public deleteModel(modelId) {
    this.service.deleteOne(modelId).subscribe(() => {
      this.fetchData();
    });
  }

  private createParams(extraParams: any = {}): any {
    let params = extraParams;
    Object.keys(this.extraParams).forEach(key => {
      params[key] = this.extraParams[key];
    });
    if (this._filterChange.getValue() !== null) {
      const filters = this._filterChange.getValue();
      Object.keys(filters).forEach((key: string) => {
        params[key] = filters[key];
      });
    }
    params['sort'] = this._sort.active + ',' + this._sort.direction;
    params['page'] = this._paginator.pageIndex;
    params['size'] = this._paginator.pageSize;
    return params;
  }
}
