import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';

import {AppComponent} from './app.component';
import {AuthModule, EventModule, ResourceModule, SpinnerModule} from './shared/modules';
import {AppRoutingModule} from './app-routing.module';
import {AngularMaterialModule} from './angular-material.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateFileLoader} from './shared/util/translate-file-loader';

import {BreadcrumbsComponent, ConfirmDialogComponent} from './components';

import {HomeComponent, LoginComponent, NotFoundComponent} from './pages';

import {SiteService, UserService} from './shared/services';
import {GroupsService} from './shared/services/groups.service';
import {EmailComponent} from "./pages/email/email.component";


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LoginComponent,
    HomeComponent,
    ConfirmDialogComponent,
    BreadcrumbsComponent,
    EmailComponent
  ],
  imports: [
    AngularMaterialModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ResourceModule,
    SpinnerModule,
    AuthModule.forRoot(),
    EventModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateFileLoader
      }
    })
  ],
  providers: [
    UserService,
    SiteService,
    GroupsService
  ],
  entryComponents: [
    ConfirmDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
