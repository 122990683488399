import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit, OnDestroy {
  sub: Subscription;
  path: string = '';

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.sub = this.router.events.subscribe(() => {
      if (this.router.url !== '') {
        this.path = this.router.url;
      }
    });
    this.path = this.router.url;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
