import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,
              private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['login']);
      return false;
    }

    const allowedRoles: string[] = next.data['roles'];
    if (!allowedRoles || allowedRoles.length === 0) {
      return true;
    }

    return Observable.create(o => {
      this.authService.getAuthUser().subscribe(user => {
        if (!user || !user.role) {
          this.router.navigate(['/home']);
          o.next(false);
          o.complete();
        }
        if (allowedRoles.indexOf(user.role.name) !== -1) {
          o.next(true);
        } else {
          this.router.navigate(['/home']);
          o.next(false);
        }
        o.complete();
      });
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class NonAuthGuard implements CanActivate {

  constructor(private authService: AuthService,
              private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let goTo = next.data['goTo'];
    if (this.authService.isLoggedIn()) {
      this.router.navigate([goTo || 'home']);
      return false;
    }
    return true;
  }
}
