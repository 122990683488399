import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from  '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {EmailService} from "../../shared/services/email.service";
import {of} from "rxjs";

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css'],
})

export class EmailComponent implements OnInit {
  @ViewChild("fileUpload") fileUpload: ElementRef;
  files  = [];
  constructor(private emailService: EmailService) { }


  ngOnInit() {
  }
  uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file);
    file.inProgress = true;
    this.emailService.upload(formData).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        return of(`${file.data.name} upload failed.`);
      })).subscribe((event: any) => {
        if (typeof (event) === 'object') {
          console.log(event.body);
        }
    });
  }
  onClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++)
      {
        const file = fileUpload.files[index];
        this.uploadFile(file);
        this.files.push({ name: file.name,data: file, inProgress: false, progress: 0});
      }
    };
    fileUpload.click();
  }

}
