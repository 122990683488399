import {Injectable} from '@angular/core';
import {GenericResponse, RestService} from 'ngx-restful';
import {HttpClient} from '@angular/common/http';
import {User} from '../models';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Role} from '../models/role';

@Injectable()
export class UserService extends RestService<User, GenericResponse> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getBaseUrlPath(): string {
    return environment.apiBase + 'users';
  }

  public getUsernameAvailable(username: string): Observable<boolean> {
    return this.get(this.getBaseUrlPath() + '/available/username/' + username)
      .pipe(map(resp => resp['success']));
  }

  public getEmailAvailable(email: string): Observable<boolean> {
    return this.get(this.getBaseUrlPath() + '/available/email/' + email)
      .pipe(map(resp => resp['success']));
  }

  public getRoles(): Observable<Role[]> {
    return this.get(environment.baseUrl + 'auth/roles');
  }
}
