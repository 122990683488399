import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LocalSpinnerComponent} from './local-spinner/local-spinner.component';
import {GlobalSpinnerComponent} from './global-spinner/global-spinner.component';
import {AngularMaterialModule} from '../../../../angular-material.module';

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule
  ],
  declarations: [LocalSpinnerComponent, GlobalSpinnerComponent],
  exports: [LocalSpinnerComponent, GlobalSpinnerComponent]
})
export class SpinnerModule {
}

