import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {RestService} from 'ngx-restful';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material';

@Component({
  selector: 'app-resource-tree',
  templateUrl: './resource-tree.component.html',
  styleUrls: ['./resource-tree.component.css']
})
export class ResourceTreeComponent implements OnInit, OnChanges {
  treeControl = new NestedTreeControl<TreeNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<TreeNode>();

  @Input() title: string = 'Resources';
  @Input() subtitle: string = 'Listed out.';
  @Input() emptyText: string = 'Empty table';
  @Input() service: RestService<any, any>;
  @Input() buttons: Array<TreeButton> = [];
  @Input() extraPath: string = null;
  @Input() extraParams: any = {};
  @Input() emptyResultParams: any = {limit: 0};
  @Input() config: TreeConfig = null;
  @Input() actions: Array<TreeAction> = [];

  @Output() action = new EventEmitter<TreeEvent>();
  @Output() button = new EventEmitter<TreeEvent>();
  @Output() contextMenu = new EventEmitter<TreeEvent>();

  loading: boolean = true;
  total: number = 0;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.service) {
      let params = this.emptyResultParams;
      Object.keys(this.extraParams).forEach(key => {
        params[key] = this.extraParams[key];
      });
      this.service.getResponse({params: params}).subscribe(resp => {
        this.total = +resp.headers.get('X-Total-Count');
        this.initTree();
      });
    }
  }

  getData(): Array<TreeNode> {
    return this.dataSource.data;
  }

  reloadTree(): void {
    this.ngOnChanges(null);
  }

  refreshTree(): void {
    let data = this.getData();
    this.dataSource.data = null;
    this.dataSource.data = data;
  }

  initTree(): void {
    let params = this.extraParams;
    params[this.config.parentName] = 'null';
    this.service.query({params: params}, this.extraPath).subscribe(elements => {
      this.dataSource.data = this.generateNodes(elements, this.config.name);
      this.loading = false;
    });
  }

  generateNodes(elements: any[], type: string, level: number = 0) {
    let children = [];
    elements.forEach(element => {
      if (this.config.childrenName instanceof Array) {
        let withChildren = this.config.childrenName.filter(cn => element[cn] && element[cn].length > 0);
        if (withChildren.length > 0) {
          let combinedChildren = [];
          withChildren.forEach(childName => {
            combinedChildren.push(...this.generateNodes(element[childName], childName, level + 1));
          });
          children.push({data: element, name: element.name, type: type, level: level, children: combinedChildren});
        } else {
          children.push({data: element, name: element.name, type: type, level: level, children: []});
        }
      } else {
        if (element[this.config.childrenName] && element[this.config.childrenName].length > 0) {
          children.push({
            data: element, name: element.name, type: type, level: level,
            children: this.generateNodes(element[this.config.childrenName], this.config.childrenName, level + 1)
          });
        } else {
          children.push({data: element, name: element.name, type: type, level: level, children: []});
        }
      }
    });
    if (this.config.sorts && this.config.sorts.length > 0) {
      let sortType = this.config.sorts.find(s => s.name === type);
      if (sortType) {
        children = children.sort((n1, n2) => sortType.sortFunc(n1.data, n2.data));
      }
    }
    return children;
  }

  getIcon(node: TreeNode, isOpen: boolean): string {
    if (!this.config.icons) {
      return isOpen ? 'expand_more' : 'chevron_right';
    }
    let icon = this.config.icons.find(i => i.name === node.type);
    if (!icon) {
      return isOpen ? 'expand_more' : 'chevron_right';
    }
    if (icon.customFunc) {
      let customIcon = icon.customFunc(node.data);
      if (customIcon) {
        return customIcon;
      }
    }
    return isOpen ? icon.openIcon : icon.closedIcon;
  }

  getActions(node: TreeNode): TreeAction[] {
    return this.actions.filter(a => a.childrenNameApply.indexOf(node.type) > -1);
  }

  emitAction(action: TreeAction, node: TreeNode): void {
    this.action.emit({name: action.name, data: node.data});
  }

  emitButton(name): void {
    this.button.emit({name: name});
  }

  emitContextMenu(event: MouseEvent, node: TreeNode): void {
    this.contextMenu.emit({name: 'contextMenu', data: {value: node.data, level: node.level, event: event}});
  }

  hasChild = (_: number, node: TreeNode) => !!node.children && node.children.length > 0;
}

export interface TreeNode {
  data: any;
  name: string;
  type: string;
  level: number;
  children?: TreeNode[];
}

export interface TreeConfig {
  name: string;
  childrenName: string | string[];
  parentName: string;
  icons?: Array<{ name: string, closedIcon: string, openIcon: string, customFunc?: any }>;
  sorts?: Array<{ name: string, sortFunc: any }>;
  showHeader?: boolean;
}

export interface TreeButton {
  name: string;
  text?: string;
  icon?: string;
  color?: string;
  classes?: string;
}

export interface TreeAction {
  name: string;
  childrenNameApply: string[];
  leafApply?: boolean;
  topApply?: boolean;
  text?: string;
  icon?: string;
  color?: string;
  classes?: string;
}

export interface TreeEvent {
  name: string;
  data?: any;
}
