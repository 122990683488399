import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthService} from '../../shared/modules/auth/auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  hide: boolean = true;

  @Output()
  success = new EventEmitter<boolean>();

  constructor(private formBuilder: FormBuilder,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: [true, []],
    });
  }

  submitLogIn() {
    if (!this.loginForm.invalid) {
      this.authService.submitLogIn(this.loginForm.get('username').value,
        this.loginForm.get('password').value,
        this.loginForm.get('rememberMe').value === true
      ).subscribe(() => {
        this.success.emit(true);
      }, () => {
        this.loginForm.get('password').setErrors({'incorrect': true});
      });
    }
  }
}
