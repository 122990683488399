import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatPaginator, MatSort} from '@angular/material';
import {RestService} from 'ngx-restful';
import {ResourceDataSource} from './resource-datasource';
import {SelectionChange, SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'app-resource-table',
  templateUrl: './resource-table.component.html',
  styleUrls: ['./resource-table.component.css']
})
export class ResourceTableComponent implements OnInit, OnChanges {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() title: string = 'Resources';
  @Input() subtitle: string = 'Listed out.';
  @Input() emptyText: string = 'Empty table';
  @Input() service: RestService<any, any>;
  @Input() extraPath: string = null;
  @Input() extraParams: any = {};
  @Input() emptyResultParams: any = {limit: 0};
  @Input() enableSelection: boolean = false;
  @Input() selectionColor: string | any = 'primary';
  @Input() highlightRows: boolean = true;
  @Input() columns: Array<TableColumn> = [];
  @Input() actions: Array<TableAction> = [];
  @Input() buttons: Array<TableButton> = [];
  @Input() filters: Array<FilterField> = [];
  @Input() pageSize: number = 25;
  @Input() pageIndex: number = 0;
  @Input() pageOptions: Array<number> = [5, 10, 25, 50, 100];
  @Input() sortBy: string = 'createdAt';
  @Input() desc: boolean = true;
  @Input() dateFormat: string = 'yyyy/MM/dd';
  @Input() timeFormat: string = 'HH:mm:ss';
  @Input() dateTimeFormat: string = 'yyyy/MM/dd HH:mm:ss';

  @Output() action = new EventEmitter<TableEvent>();
  @Output() button = new EventEmitter<TableEvent>();
  @Output() selectionChanged = new EventEmitter<SelectionChange<any>>();

  loading: boolean = true;
  total: number = 0;
  colType = ColumnType;
  filterType = FilterType;

  filterTimeout: any = null;

  public dataSource: ResourceDataSource<any>;
  public selection = new SelectionModel<any>(true, []);

  ngOnInit(): void {
    this.selection.changed.subscribe(data => {
      this.selectionChanged.emit(data);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.service) {
      this.service.getResponse({params: this.prepareParams()}).subscribe(resp => {
        this.total = +resp.headers.get('X-Total-Count');
        this.initTable();
        this.loading = false;
      });
    }
  }

  displayColumns(): string[] {
    let columns = [];
    if (this.enableSelection) {
      columns.push('selection_column_checkbox');
    }
    columns.push(...this.columns.map(col => col.name));
    return columns;
  }

  transformElement(col: TableColumn, row): any {
    let parts = col.name.split('.');
    let elem = row[parts[0]];
    for (let i = 1; i < parts.length; i++) {
      elem = elem[parts[i]];
    }
    if (col.transform) {
      if (elem !== undefined) {
        elem = col.transform(elem);
      } else {
        elem = col.transform(row);
      }
    }
    if (col.type === ColumnType.BOOLEAN || col.type === ColumnType.TOGGLE) {
      return elem;
    }
    return elem ? elem : '-';
  }

  emitAction(name, row): void {
    this.action.emit({name: name, data: row});
  }

  emitButton(button: TableButton): void {
    if (!button.children || button.children.length === 0) {
      this.button.emit({name: button.name});
    }
  }

  applyFilter(filterValue: any) {
    if (this.dataSource) {
      this.dataSource.filter = filterValue;
      this.service.getResponse({params: this.prepareParams({})}).subscribe(resp => {
        this.total = +resp.headers.get('X-Total-Count');
        this.selection.clear();
      });
    }
  }

  filterChanged(filter: FilterField, value: any): void {
    filter.value = value;
    if (this.filterTimeout !== null) {
      clearTimeout(this.filterTimeout);
    }
    this.filterTimeout = setTimeout(() => {
      let values = this.getFilterValues();
      this.applyFilter(values);
      this.service.getResponse({params: this.prepareParams(values)}).subscribe(resp => {
        this.total = +resp.headers.get('X-Total-Count');
      });
      this.filterTimeout = null;
    }, 500);
  }

  initTable(): void {
    this.sort.active = this.sortBy;
    this.sort.start = this.desc ? 'desc' : 'asc';
    this.sort.direction = this.desc ? 'desc' : 'asc';
    this.paginator.pageSize = this.pageSize;
    this.paginator.pageIndex = this.pageIndex;
    if (this.actions.length > 0 && !this.columns.find(col => col.type === ColumnType.ACTION)) {
      this.columns.push({name: 'actions', title: 'Actions', type: ColumnType.ACTION});
    }
    this.dataSource = new ResourceDataSource<any>(this.service, this.sort, this.paginator, this.extraPath, this.extraParams);
  }

  toggleAction(event, row, col): void {
    this.action.emit({name: col.name, type: EventType.TOGGLE, data: {row: row, event: event}});
  }

  getSelectedRows(): any[] {
    return this.selection.selected;
  }

  getFilterValues(): any {
    let values = {};
    this.filters.forEach(filter => {
      if (filter.value !== undefined && filter.value !== '' && filter.value !== null) {
        values[filter.name] = filter.value;
      }
    });
    return values;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    return this.selection.selected.length === this.dataSource.data.value.length;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.value.forEach(row => this.selection.select(row));
  }

  private prepareParams(values = {}): any {
    let params = this.emptyResultParams;
    Object.keys(this.extraParams).forEach(key => {
      params[key] = this.extraParams[key];
    });
    Object.keys(values).forEach(key => {
      params[key] = values[key];
    });
    return params;
  }
}

export interface TableColumn {
  name: string;
  title: string;
  type: ColumnType;
  options?: ColumnOptions;
  transform?: (elem) => any | null;
}

export interface ColumnOptions {
  sortable?: boolean;
  flex?: number;
  width?: string;
  alignment?: string;
}

export interface TableAction {
  name: string;
  icon: string;
  tooltip?: string;
  classes?: string;
  excludeIds?: number[];
  routerLinkFn?: (row) => string | Array<any>;
  queryParamsFn?: (row) => any;
}

export interface TableButton {
  name: string;
  text?: string;
  icon?: string;
  color?: string;
  classes?: string;
  children?: Array<TableButton>;
  routerLinkFn?: () => string | Array<any>;
  queryParamsFn?: () => any;
}

export interface TableEvent {
  name: string;
  type?: EventType;
  data?: any;
}

export interface FilterField {
  name: string;
  placeholder: string;
  type?: FilterType;
  value?: any;
  options?: Array<{ key: any, value: any }>;
  icon?: string;
}

export enum FilterType {
  STRING,
  NUMBER,
  SELECT
}

export enum EventType {
  ACTION,
  BUTTON,
  TOGGLE
}

export enum ColumnType {
  INTEGER,
  DECIMAL,
  STRING,
  BOOLEAN,
  DATE,
  TIME,
  DATETIME,
  TOGGLE,
  ACTION
}
