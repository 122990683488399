import {SiteLanguage} from './site-language';

export class Site {
  id: number;
  name: string;
  domain: string;
  gaId: string;
  pixelId: string;
  languages: Array<SiteLanguage> = [];
  screenshotName: string;
  enabled: boolean;
  createdAt: Date;
  updatedAt: Date;
}
