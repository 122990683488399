import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {Event as NavigationEvent, NavigationEnd, Router} from '@angular/router';
import {AuthService} from './shared/modules/auth/auth.service';
import {Site, User} from './shared/models';
import {TranslateService} from '@ngx-translate/core';
import {MatSidenav} from '@angular/material';
import {environment} from '../environments/environment';
import {SiteService} from './shared/services';
import {EventBroker} from './shared/modules/event/event-broker';
import {Topic} from './shared/modules/event/topic.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  collapsed: boolean = false;
  isMobile: boolean = false;
  showLogin: boolean = false;
  navigationUrl: String;
  breadcrumbs: Array<String>;
  selfUser: User;

  sites: Array<Site> = [];
  selectedSite: Site = null;

  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(public siteService: SiteService, public translate: TranslateService,
              private authService: AuthService,
              private router: Router,
              private eventBroker: EventBroker) {
    translate.setDefaultLang('en');
    translate.use(localStorage.getItem('locale') || environment.locale);
    this.translate.onLangChange.subscribe(change => {
      localStorage.setItem('locale', change.lang);
    });
    this.onResize();
    router.events.forEach((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.navigationUrl = event.url;
        this.breadcrumbs = [];
      }
    });
    this.eventBroker.getEvent(Topic.SITE_CREATED).subscribe(data => {
      this.switchSite(data.site);
      this.sites.push(data.site);
    });
    this.eventBroker.getEvent(Topic.SITE_UPDATED).subscribe(data => {
      this.siteService.getOne(data.site.id).subscribe(site => {
        this.sites[this.sites.findIndex(s => s.id === site.id)] = site;
        if (this.selectedSite.id === site.id) {
          this.switchSite(site);
        }
      });
    });
    this.eventBroker.getEvent(Topic.SITE_DELETED).subscribe(data => {
      this.sites.splice(this.sites.findIndex(s => s.id === data.site.id), 1);
      if (this.selectedSite.id === data.site.id) {
        this.switchSite(this.sites.length > 0 ? this.sites[0] : null);
      }
    });
  }

  selectSite(): void {
    this.siteService.getAll().subscribe((sites) => {
      this.sites = sites;

      if (sites.length > 0) {
        let preselectedSiteId = this.siteService.getCurrentSiteId();

        if (preselectedSiteId && sites.find(s => s.id === preselectedSiteId)) {
          let preselectedSite = this.sites.find(x => x.id === preselectedSiteId);
          this.switchSite(preselectedSite);
        } else {
          this.switchSite(this.sites[0]);
        }
      } else {
        this.selectedSite = undefined;
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.isMobile = (window.innerWidth <= 767);
  }

  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.authService.getAuthUser().subscribe(user => {
        this.selfUser = user;
        if (user !== null) {
          this.selectSite();
        }
      }, () => {
        this.selfUser = null;
        this.showLogin = true;
      });
    } else {
      this.showLogin = true;
    }
  }

  loggedIn(success: boolean): void {
    this.authService.getAuthUser().subscribe(user => {
      this.selfUser = user;
      this.showLogin = false;
      if (success) {
        this.selectSite();
      }
    }, () => {
      this.selfUser = null;
      this.showLogin = true;
    });
  }

  logOut(): void {
    this.authService.logOut().subscribe(resp => {
      this.selfUser = null;
      this.showLogin = true;
    });
  }

  menuClicked(): void {
    if (this.isMobile) {
      this.collapsed = false;
      this.sidenav.toggle();
    } else {
      this.sidenav.open();
      this.collapsed = !this.collapsed;
    }
  }

  closeSidenav(): void {
    if (this.isMobile && this.sidenav.opened) {
      this.sidenav.close();
    }
  }

  isLocale(locale): boolean {
    return this.translate.currentLang === locale;
  }

  changeLocale(locale): void {
    this.translate.use(locale);
  }

  switchSite(site): void {
    this.selectedSite = site;
    this.siteService.setCurrentSite(site);
  }
}
