import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {OAUTH_CLIENT_ID, OAUTH_CLIENT_SECRET, OAUTH_GRANT_TYPE, OAUTH_SCOPE} from './app.config-oauth';
import {User} from '../../models';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

@Injectable()
export class AuthService {
  STORE_TYPE: string = 'store-cms-dashboard';
  AUTH_KEY: string = 'authorization-cms-dashboard';
  _user: User;

  constructor(private http: HttpClient,
              private router: Router) {
  }

  getBaseUrlPath(): string {
    return environment.baseUrl + 'oauth';
  }

  public submitLogIn(username: string, password: string, rememberMe: boolean = true): Observable<any> {
    let params = new HttpParams()
      .set('grant_type', OAUTH_GRANT_TYPE)
      .set('scope', OAUTH_SCOPE)
      .set('username', username)
      .set('password', password);
    let headers = new HttpHeaders().set('Authorization', 'Basic ' + btoa(OAUTH_CLIENT_ID + ':' + OAUTH_CLIENT_SECRET));
    return this.http.post(this.getBaseUrlPath() + '/token', params, {headers: headers})
      .pipe(tap(data => this.setAuthData(data, rememberMe)), catchError(err => this.handleError(err)));
  }

  public logOut(): Observable<any> {
    return this.http.get(environment.baseUrl + 'auth/logout').pipe(
      tap((res) => this.removeAuthData()),
      tap((res) => this.router.navigate(['/'])),
      catchError(err => this.handleError(err))
    );
  }

  public setAuthData(authData: any, isLocalStorage = true): void {
    authData['expiration_date'] = this.tokenExpirationDateStr(authData);
    let dataStr = JSON.stringify(authData);
    if (isLocalStorage) {
      localStorage.setItem(this.STORE_TYPE, 'local');
      localStorage.setItem(this.AUTH_KEY, dataStr);
    } else {
      localStorage.setItem(this.STORE_TYPE, 'session');
      sessionStorage.setItem(this.AUTH_KEY, dataStr);
    }
  }

  public getAuthData(): any {
    let storage = localStorage.getItem(this.STORE_TYPE);
    let data = '';
    if (storage === 'local') {
      data = localStorage.getItem(this.AUTH_KEY);
    } else {
      data = sessionStorage.getItem(this.AUTH_KEY);
    }
    if (data && data !== '') {
      return JSON.parse(data);
    } else {
      return null;
    }
  }

  public removeAuthData(): void {
    let storage = localStorage.getItem(this.STORE_TYPE);
    localStorage.removeItem(this.STORE_TYPE);
    if (storage === 'local') {
      localStorage.removeItem(this.AUTH_KEY);
    } else {
      sessionStorage.removeItem(this.AUTH_KEY);
    }
    this._user = null;
  }

  public getAccessToken(): string {
    let data = this.getAuthData();
    if (data) {
      return data['access_token'];
    }
  }

  public getRefreshToken(): string {
    let data = this.getAuthData();
    if (data) {
      return data['refresh_token'];
    }
  }

  set user(user: User) {
    this._user = user;
  }

  get user(): User {
    return this._user;
  }

  public getAuthUser(): Observable<User> {
    if (!this.isLoggedIn()) {
      return Observable.create(o => {
        o.next(null);
        o.complete();
      });
    }
    return this.http.get<User>(environment.baseUrl + 'auth/user').pipe(
      tap(resp => this._user = resp),
      catchError(err => this.handleError(err))
    );
  }

  public isLoggedIn(): boolean {
    return !!this.getAuthData();
  }

  private handleError(error: any) {
    this.removeAuthData();
    this.router.navigate(['/login']);
    return throwError(error);
  }

  private tokenExpirationDateStr(authData): string {
    if (authData) {
      let secondsLeft = +authData['expires_in'];
      if (secondsLeft) {
        let date = new Date();
        date.setTime(date.getTime() + (secondsLeft * 1000));
        return date.toUTCString();
      }
    }
  }
}
