import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup} from '@angular/forms';
import {ValidatorFn} from '@angular/forms';
import * as moment from 'moment';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-resource-form',
  templateUrl: './resource-form.component.html',
  styleUrls: ['./resource-form.component.css']
})
export class ResourceFormComponent implements OnInit, OnChanges, DoCheck {
  @Input() isPlain: boolean = false;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() resource: any;
  @Input() fields: Array<FormField> = [];
  @Input() options: any = {};
  @Input() buttons: Array<FormButton> = [];

  @Output() button = new EventEmitter<FormEvent>();
  @Output() fieldChanged = new EventEmitter<FormEvent>();

  loading: boolean = true;
  fieldType = FieldType;

  resourceForm: FormGroup;

  constructor(private _fb: FormBuilder) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.resource) {
      this.initForm();
      this.loading = false;
    }
  }

  ngDoCheck() {
    this.fields.forEach(field => {
      let fieldControl = this.getControl(field);
      if (field.type === FieldType.AUTOCOMPLETE && field.options && field.options['values'] &&
        field.options['values'].length > 0 && !field['filterSet'] && fieldControl) {
        // This is array is required since autocomplete field value contains search query.
        let fieldValue = fieldControl.value;
        if (typeof fieldValue !== 'string') {
          field['selectedValues'] = fieldValue || [];
        }
        // initializing async search filter
        field['filteredOptions'] = fieldControl.valueChanges
          .pipe(startWith(''), map(value => this.filterAutocomplete(field, value)));
        field['filterSet'] = true;
      }
    });
  }

  ngOnInit(): void {

  }

  emitButton(name): void {
    if (this.resourceForm.invalid) {
      this.markFormGroupTouched(this.resourceForm);
    }
    this.autocompleteSetSelectedValues();
    let values = this.resourceForm.getRawValue();

    this.button.emit({valid: !this.resourceForm.invalid, name: name, data: this.prepareData(values)});
  }

  getError(field: FormField): any[] {
    let control = this.getControl(field);
    control.markAsTouched();
    if (this.isError(control, 'required')) {
      return ['components.resourceForm.required', {}];
    }
    if (this.isError(control, 'email')) {
      return ['components.resourceForm.invalidEmail', {}];
    }
    if (this.isError(control, 'maxlength')) {
      return ['components.resourceForm.maxlength', {value: control.getError('maxlength').requiredLength}];
    }
    if (this.isError(control, 'minlength')) {
      return ['components.resourceForm.minlength', {value: control.getError('minlength').requiredLength}];
    }
    if (this.isError(control, 'max')) {
      return ['components.resourceForm.max', {value: control.getError('max').max}];
    }
    if (this.isError(control, 'min')) {
      return ['components.resourceForm.min', {value: control.getError('min').min}];
    }
    if (this.isError(control, 'mustMatch')) {
      return ['components.resourceForm.mustMatch', {}];
    }
    if (this.isError(control, 'taken')) {
      return ['components.resourceForm.taken', {value: field.name}];
    }
    return ['components.resourceForm.invalid', {}];
  }

  isError(control: any, errorCode: string): boolean {
    return control && control.hasError(errorCode) && (control.dirty || control.touched);
  }

  isInvalid(field: FormField): boolean {
    let control = this.getControl(field);
    return control && control.invalid && (control.dirty || control.touched);
  }

  compareByName(f: any, s: any): boolean {
    return f === s;
  }

  compareById(f: any, s: any): boolean {
    return f && s && f.id === s.id;
  }

  onKey(event: any, func: (arg) => any) {
    if (func) {
      func(event);
    }
  }

  private getControl(field: FormField): AbstractControl {
    if (!this.resourceForm) {
      return null;
    }
    return this.resourceForm.get(field.name);
  }

  private initForm(): void {
    if (this.resourceForm) {
      this.fields.forEach(field => {
        if (field.type === FieldType.AUTOCOMPLETE) {
          this.resourceForm.removeControl(field.name);
          this.resourceForm.addControl(field.name,
            this._fb.control(this.resource[field.name] || '', field.validators || [], field.asyncValidator));
        }
      });
      return;
    }
    let group = {};
    this.fields.forEach(field => {
      if (field.type === FieldType.CHECKBOX) {
        group[field.name] = this._fb.array(this.getItems(field), field.validators || [], field.asyncValidator);
      } else if (field.type === FieldType.RADIOBUTTON) {
        group[field.name] = [this.getOptionsValue(field) || '', field.validators || [], field.asyncValidator];
      } else if (field.type === FieldType.DATETIMEPICKER || field.type === FieldType.DATEPICKER) {
        group[field.name] = [this.resource[field.name] ? moment(this.resource[field.name]) : '', field.validators || [],
          field.asyncValidator];
      } else {
        group[field.name] = [this.resource[field.name] || '', field.validators || [], field.asyncValidator];
      }
    });
    this.resourceForm = this._fb.group(group, this.options);
    this.fields.forEach(field => {
      this.resourceForm.get(field.name).valueChanges.subscribe(value => {
        this.fieldChanged.emit({valid: true, name: field.name, data: value});
      });
    });
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  private getOptionsValue(field): any {
    let valueKey = field.options['valueKey'];
    for (let i = 0; i < field.options['values'].length; i++) {
      let value = '';
      if (valueKey) {
        value = field.options['values'][i]['value'][valueKey];
        if (this.resource[field.name] && this.resource[field.name][valueKey] === value) {
          return field.options['values'][i]['value'];
        }
      } else {
        value = field.options['values'][i]['value'];
        if (this.resource[field.name] && this.resource[field.name] === value) {
          return field.options['values'][i]['value'];
        }
      }
    }
  }

  private getItems(field): any[] {
    let valueKey = field.options['valueKey'];
    let controls = field.options['values'].map(() => this._fb.control(false));
    for (let i = 0; i < field.options['values'].length; i++) {
      let value = field.options['values'][i]['value'][valueKey];
      for (let j = 0; j < this.resource[field.name].length; j++) {
        let resVal = this.resource[field.name][j][valueKey];
        if (resVal === value) {
          controls[i].setValue(true);
        }
      }
    }
    return controls;
  }

  private prepareData(values: any): any {
    let data = {};
    Object.keys(values).forEach(key => {
      let value = values[key];
      let checkboxField = this.fields.find(f => f.name === key && f.type === FieldType.CHECKBOX);
      if (checkboxField && value.length > 0) {
        let realObjects = [];
        for (let i = 0; i < value.length; i++) {
          let option = checkboxField.options['values'][i];
          if (value[i] === true) {
            realObjects.push(option['value']);
          }
        }
        data[key] = realObjects;
        return;
      }
      let autoField = this.fields.find(f => f.name === key && f.type === FieldType.AUTOCOMPLETE &&
        f.options && f.options['chipList']);
      if (autoField && value && value.length > 0) {
        data[key] = autoField['selectedValues'];
        return;
      }
      let toggleField = this.fields.find(f => f.name === key && f.type === FieldType.TOGGLE);
      if (toggleField && value === '') {
        value = false;
      }
      data[key] = value;
    });

    return data;
  }

  /**
   * Autocomplete custom display function
   *
   * @param option selected option
   */
  displayAutocomplete(option): string {
    if (option) {
      return option['name'] ? option['name'] : '';
    } else {
      return '';
    }
  }

  /**
   * Add selected value to forms selectedValues array.
   *
   * @param field FormField object
   * @param value selected value object
   */
  autocompleteSelect(field: FormField, value: any) {
    if ('multiple' in field.options && field.options.multiple === true) {
      if (!('selectedValues' in field)) {
        field['selectedValues'] = [];
      }
      if (field['selectedValues'].indexOf(value) === -1) {
        field['selectedValues'].push(value);
      }
      this.getControl(field).setValue(null);
    }
  }

  /**
   * Remove value to forms selectedValues array.
   * @param field FormField object
   * @param value selected value object
   */
  removeFromSelectedList(field: FormField, value: any) {
    field['selectedValues'] = field['selectedValues'].filter(item => item !== value);
    this.getControl(field).setValue(null);
  }

  /**
   * Sets selectedValues array to fields value attribute. Use this before submitting a form that has autocomplete fields.
   */
  autocompleteSetSelectedValues() {
    this.fields.forEach(field => {
      if (field.type === FieldType.AUTOCOMPLETE && 'multiple' in field.options && field.options.multiple === true) {
        this.getControl(field).setValue(field['selectedValues']);
      }
    });
  }

  /**
   * Custom filter used for the autocomplete async displayed options.
   * @param field FormField object
   * @param value string search value
   */
  private filterAutocomplete(field, value: string): string[] {
    let valueKey = field['options']['valueKey'];
    if (!('selectedValues' in field)) {
      field['selectedValues'] = [];
    } else if (!Array.isArray(field['selectedValues'])) {
      field['selectedValues'] = [field['selectedValues']];
    }

    // if search value is entered into the filter
    if (value && value.toLowerCase && value.length > 0) {
      const filterValue = value.toLowerCase();
      return field.options['values'].filter(option => {
        return this.filterByFieldValues(field.options['filterKeys'], option, filterValue) &&
          field['selectedValues'].map(e => e[valueKey]).indexOf(option['value'][valueKey]) === -1;
      });
      // if search value is not entered into the filter and some values are already selected
    } else if (field['selectedValues'] && field['selectedValues'].length > 0) {
      return field.options['values'].filter(option => {
        return field['selectedValues'].map(e => e[valueKey]).indexOf(option['value'][valueKey]) === -1;
      });
      // if search value is not entered into the filter and no values are already selected
    } else {
      return field.options['values'];
    }
  }

  private filterByFieldValues(filterKeys: string[], option: any, filterValue: string): boolean {
    if (!filterKeys || filterKeys.length === 0) {
      return option['name'].toLowerCase().includes(filterValue);
    }
    let optionValue = option['value'];
    let match = false;
    filterKeys.forEach(value => {
      if (optionValue && optionValue[value].toLowerCase().includes(filterValue)) {
        match = true;
      }
    });
    return match;
  }
}

export interface FormField {
  name: string;
  label: string;
  type: FieldType;
  hint?: string;
  options?: InputOptions | TextareaOptions | SelectOptions | ToggleOptions | DatepickerOptions | RadiobuttonOptions
    | CheckboxOptions | AutocompleteOptions;
  validators?: Array<ValidatorFn>;
  asyncValidator?: AsyncValidatorFn;
  onKeyUp?: (event) => any | null;
  onKeyDown?: (event) => any | null;
  onKeyPress?: (event) => any | null;
  classes?: string;
}

export interface FormButton {
  name: string;
  text?: string;
  icon?: string;
  color?: string;
  classes?: string;
}

export interface FormEvent {
  valid: boolean;
  name: string;
  data?: any;
}

export enum FieldType {
  INPUT,
  TEXTAREA,
  SELECT,
  TOGGLE,
  DATEPICKER,
  DATETIMEPICKER,
  RADIOBUTTON,
  CHECKBOX,
  TIMEPICKER,
  AUTOCOMPLETE
}

export interface InputOptions {
  type?: 'text' | 'number' | 'password' | 'email' | 'tel' | 'url';
  color?: string;
  clearable?: boolean;
}

export interface TextareaOptions {
  maxRows?: number;
  minRows?: number;
  color?: string;
  clearable?: boolean;
}

export interface SelectOptions {
  multiple?: boolean;
  allowEmpty?: boolean;
  valueKey?: any;
  values?: Array<{ value: any, name: string }>;
  color?: string;
  clearable?: boolean;
}

export interface ToggleOptions {
  labelPosition?: 'before' | 'after';
  togglePosition?: 'start' | 'end';
  color?: string;
}

export interface DatepickerOptions {
  startDate?: Date;
  startView?: 'month' | 'year' | 'multi-year';
  maxDate?: Date;
  minDate?: Date;
  color?: string;
  focusOpen?: boolean;
  clearable?: boolean;
}

export interface RadiobuttonOptions {
  labelPosition?: 'before' | 'after';
  orientation?: 'horizontal' | 'vertical';
  valueKey?: any;
  values?: Array<{ value: any, name: string }>;
  color?: string;
}

export interface CheckboxOptions {
  labelPosition?: 'before' | 'after';
  orientation?: 'horizontal' | 'vertical';
  valueKey?: any;
  values?: Array<{ value: any, name: string }>;
  color?: string;
}

export interface AutocompleteOptions {
  multiple?: boolean;
  chipList?: boolean;
  valueKey?: any;
  values?: Array<{ value: any, name: string }>;
  selectable?: boolean;
  removable?: boolean;
  filterKeys?: string[];
  displayTemplate: TemplateRef<any>;
  clearable?: boolean;
}
