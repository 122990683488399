import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {
  HomeComponent,
  NotFoundComponent
} from './pages';
import {EmailComponent} from "./pages/email/email.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'sites',
    loadChildren: './pages/sites/sites.module#SitesModule',
  },
  {
    path: 'login',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'users',
    loadChildren: './pages/users/users.module#UsersModule',
  },
  {
    path: 'email',
    component: EmailComponent
  },
  {
    path: 'groups',
    loadChildren: './pages/groups/groups.module#GroupsModule',
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
