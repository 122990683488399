import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilePipe} from './file.pipe';
import {TruncatePipe} from './truncate.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [FilePipe, TruncatePipe],
  exports: [FilePipe, TruncatePipe]
})
export class PipesModule {
}
