import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router, Event as NavigationEvent} from '@angular/router';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent implements OnInit {
  currentUrl: String = '';
  breadcrumbs: Array<Breadcrumb>;

  constructor(private router: Router) {
    router.events.forEach((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        this.updateCrumbs();
      }
    });
  }

  ngOnInit(): void {
    this.currentUrl = this.router.url;
    this.updateCrumbs();
  }

  private updateCrumbs(): void {
    this.breadcrumbs = [];
    let urlParts = this.currentUrl.split('/');
    for (let i = 1; i < urlParts.length; i++) {
      let part = urlParts[i];
      if (!part || part.trim() === '' || !isNaN(+part)) {
        return;
      }
      let queryIndex = part.lastIndexOf('?');
      if (queryIndex !== -1) {
        part = part.substring(0, queryIndex);
      }
      let text = 'app.';
      let link = '/' + part;
      if (i >= 2) {
        text = 'common.';
      }
      let id = null;
      if (part === 'edit' && urlParts.length >= i) {
        link += '/' + urlParts[i + 1];
        if (!isNaN(+urlParts[i + 1])) {
          id = +urlParts[i + 1];
        }
      }
      text += part;
      this.breadcrumbs.push({text: text, link: link, id: id});
    }
  }
}

export interface Breadcrumb {
  text: string;
  id?: number;
  link: string;
}
