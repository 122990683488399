import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Chart} from 'chart.js';
import {Site} from '../../shared/models';
import {SiteService} from '../../shared/services';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  selectedSite: Site;
  actionsGraph: any;
  totalPages: number = 10;
  totalCategories: number = 10;
  totalUsers: number = 10;
  totalEvents: number = 100;
  registeredEvents = [
    {'name': 'pageView', 'number': 25},
    {'name': 'videoView', 'number': 15},
    {'name': 'readingEntered', 'number': 45}
  ];

  @ViewChild('actionsGraphViewChild') private actionsGraphRef;

  private unsubscribe: Subject<void> = new Subject();

  constructor(public siteService: SiteService) {
  }

  ngOnInit(): void {
    this.siteService.siteChanged().pipe(takeUntil(this.unsubscribe)).subscribe(newSite => {
      this.selectedSite = newSite;
    });
    this.drawActionsGraph({
      '25.08.': 55,
      '26.08.': 65,
      '27.08.': 45,
      '28.08.': 30,
      '29.08.': 45,
      '30.08.': 65,
      '31.08.': 85
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private drawActionsGraph(stats: any): void {
    let labels = Object.keys(stats).sort();
    let values = [];
    labels.forEach(key => values.push(stats[key]));
    let data = {
      labels: labels,
      datasets: [{
        backgroundColor: 'rgba(63,81,181,0.2)',
        borderColor: 'rgba(63,81,181,1)',
        borderWidth: 2,
        hoverBackgroundColor: 'rgba(63,81,181,0.4)',
        hoverBorderColor: 'rgba(63,81,181,1)',
        data: values,
      }]
    };

    let options = {
      legend: {
        display: false
      },
      maintainAspectRatio: true,
      responsive: true,
      scales: {
        yAxes: [{
          stacked: true,
          gridLines: {
            display: true,
            color: 'rgba(63,81,181,0.2)'
          }
        }],
        xAxes: [{
          gridLines: {
            display: false
          }
        }]
      }
    };

    this.actionsGraph = new Chart(this.actionsGraphRef.nativeElement.getContext('2d'), {
      type: 'line',
      options: options,
      data: data
    });
  }
}
