import {AbstractControl, FormGroup} from '@angular/forms';
import {UserService} from '../services';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({mustMatch: true});
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export class ValidateEmailNotTaken {
  static createValidator(userService: UserService, exclude: string = null) {
    return (control: AbstractControl) => {
      if (control.value === '' || control.value === exclude) {
        return Observable.create(o => {
          o.next(null);
          o.complete();
        });
      }
      return userService.getEmailAvailable(control.value).pipe(map(res => {
        return res ? null : {taken: true};
      }));
    };
  }
}

export class ValidateUsernameNotTaken {
  static createValidator(userService: UserService, exclude: string = null) {
    return (control: AbstractControl) => {
      if (control.value === '' || control.value === exclude) {
        return Observable.create(o => {
          o.next(null);
          o.complete();
        });
      }
      return userService.getUsernameAvailable(control.value).pipe(map(res => {
        return res ? null : {taken: true};
      }));
    };
  }
}
