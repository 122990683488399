import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-local-spinner',
  templateUrl: './local-spinner.component.html',
  styleUrls: ['./local-spinner.component.css']
})
export class LocalSpinnerComponent {
  @Input() margin: string = '';
}
