import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Topic} from './topic.enum';

@Injectable({providedIn: 'root'})
export class EventBroker {
  private subjects: Map<Topic, Subject<any>> = new Map();

  constructor() {
    Object.keys(Topic).map(k => Topic[k]).forEach(topic => {
      this.subjects.set(topic, new Subject<any>());
    });
  }

  sendEvent(event: any, topic: Topic): void {
    this.subjects.get(topic).next(event);
  }

  clearEvent(topic: Topic): void {
    this.subjects.get(topic).next();
  }

  getEvent(topic: Topic): Observable<any> {
    return this.subjects.get(topic).asObservable();
  }
}
