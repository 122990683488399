import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent {

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  onButton(name: string): void {
    this.dialogRef.close(name);
  }
}

export interface DialogData {
  title: string;
  text: string;
  textArgs?: any;
  showCancel?: boolean;
  buttons?: DialogButton[];
}

export interface DialogButton {
  name: string;
  text?: string;
  icon?: string;
  color?: string;
  classes?: string;
}
