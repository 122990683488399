import {Role} from './role';

export class User {
  id: number;
  role: Role;
  username: string;
  password: string;
  email: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
  createdAt: Date;
  updatedAt: Date;
}
