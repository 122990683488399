import {NgModule, ModuleWithProviders} from '@angular/core';
import {AuthInterceptor} from './auth.interceptor';
import {AuthService} from './auth.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthGuard, NonAuthGuard} from './auth.guard';

@NgModule({
  declarations: [],
  exports: []
})
export class AuthModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        AuthGuard,
        NonAuthGuard,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true
        }]
    };
  }
}
